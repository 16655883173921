import React from "react";
import { Plural } from "@lingui/macro";

const Period = ({ period, amount }) => {
  return (
    <>
      {period === "week" && <Plural value={amount} one="week" other="weeks" />}
      {period === "month" && (
        <Plural value={amount} one="month" other="months" />
      )}
    </>
  );
};

export default Period;
