import { useState, useRef, useEffect } from "react";
import { isClient } from "~/utils/app-helpers";

const usePrices = (ids) => {
  const [data, setData] = useState({});
  const [isLoading, setLoadingStatus] = useState(true);
  const [error, setError] = useState("");
  const isMounted = useRef(false);

  const getData = async (ids) => {
    try {
      const type = getPriceType();
      const couponUrl = getCoupon();
      const url = `${process.env.GATSBY_API_LP_REVIEWS}/prices/?course_ids=${ids}${couponUrl}${type}`;
      const options = {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const results = await response.json();

      if (isMounted.current) {
        setData(results);
      }
    } catch (error) {
      if (isMounted.current) setError(error.message);
    }

    if (isMounted.current) setLoadingStatus(false);
  };

  useEffect(() => {
    isMounted.current = true;

    if (ids !== "") getData(ids);

    return () => {
      isMounted.current = false;
      return false;
    };
  }, [ids]);

  return { data, isLoading, error };
};

export default usePrices;

const getPriceType = () => {
  if (isClient) {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");

    let priceType = "";

    if (type !== null) {
      sessionStorage.setItem("vtPriceType", type);

      priceType = `&type=${type}`;
    } else {
      sessionStorage.removeItem("vtPriceType");
    }

    return priceType;
  }
};

const getCoupon = () => {
  if (isClient) {
    const urlParams = new URLSearchParams(window.location.search);
    const coupon = urlParams.get("coupon");

    let couponUrl = "";

    if (coupon !== null) {
      sessionStorage.setItem("vtCoupon", coupon);

      couponUrl = `&coupon=${coupon}`;
    } else {
      const sessionCoupon = sessionStorage.getItem("vtCoupon");

      if (sessionCoupon !== null) couponUrl = `&coupon=${sessionCoupon}`;
    }

    return couponUrl;
  }
};
