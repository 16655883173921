import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Rating = React.memo((props) => {
  let { reviews_avg, className } = { ...props };
  let rating = [];

  let classes = classNames(
    // "flex",
    // "flex-row",
    "leading-none",
    // "items-center",
    "whitespace-nowrap",
    "text-brand-primary",
    "text-lg",
    "sm:space-x-1",
    className
  );

  for (let i = 1; i <= 5; i++) {
    if (i <= reviews_avg) {
      rating.push(<span className="icon-star" key={`id_r_${i}`} />);
    } else {
      if (isInt(reviews_avg)) {
        rating.push(
          <span className="icon-star text-gray-400" key={`id_r_${i}`} />
        );
      } else {
        if (
          Math.round(reviews_avg) > parseFloat(reviews_avg) &&
          i === Math.round(reviews_avg)
        )
          rating.push(
            <span className="leading-none" key={`id_r_${i}`}>
              <span
                className="icon-star-half absolute"
                style={{ fontSize: 21, marginTop: -1 }}
                key={`id_r2_${i}`}
              />
              <span className="icon-star text-gray-400" key={`id_r1_${i}`} />
            </span>
          );
        else
          rating.push(
            <span className="icon-star text-gray-400" key={`id_r_${i}`} />
          );
      }
    }
  }

  return <span className={classes}>{rating}</span>;
});

Rating.propTypes = {
  reviews_avg: PropTypes.number.isRequired,
};

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export default Rating;
