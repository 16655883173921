import React from "react";

const Loader = ({ className, reverse = false }) => {
  return (
    // <div className={`pre-loader ${className}`}>
    //   <div className="box1"></div>
    //   <div className="box2"></div>
    //   <div className="box3"></div>
    //   <div className="box4"></div>
    //   <div className="box5"></div>
    // </div>
    <>
      {reverse ? (
        <div className={`loader-reverse ${className}`} />
      ) : (
        <div className={`loader ${className}`} />
      )}
    </>
  );
};

export default Loader;
