import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CartButton = ({ price, type, exists, actionFunc, ID, image, title, link }) => {
  if (Number(price) === 0) return null;

  if (type !== "one_time_payment") return null;

  let btnClasses = classNames("btn text-center btn-cart", {
    "btn-primary-reverse": !exists,
    "btn-primary": exists,
  });

  return (
    <button className={btnClasses} style={{ width: 46, height: 36, padding: 0 }} onClick={actionFunc({ ID, image, title, link })}>
      <span className="icon-shopping-cart" />
    </button>
  );
};

CartButton.propTypes = {
  price: PropTypes.string,
  type: PropTypes.string,
  exists: PropTypes.bool,
  actionFunc: PropTypes.func,
  ID: PropTypes.number,
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
};

export default CartButton;
