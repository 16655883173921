import React, { useContext, useState, useEffect, me } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import Rating from "./rating";
import Link from "./link";
import ParagraphShortening from "./paragraphShortening";
import LazyImage from "./lazyImage";
import PricesContext from "~/providers/pricesProvider";
import Loader from "./loader";
import Period from "./transHelpers/period";
import Button from "./button";
import CourseTag from "./courseTag";
import { wpURL } from "~/utils/app-helpers";
import { useLang } from "~/../langProvider.js";
import CartButton from "./cartButton";

const CourseCard = React.memo(
  ({
    ID,
    image,
    tutor,
    title,
    reviews,
    reviews_avg,
    students,
    free,
    promo_acceptable,
    permalink,
    actionFunc,
    exists,
    total_lectures,
    created_at,
    auth,
  }) => {
    const prices = JSON.stringify(useContext(PricesContext));
    const [price, set_price] = useState("");
    const [price_old, set_price_old] = useState("");
    const [price_type, set_price_type] = useState("");
    const [details, set_details] = useState("");
    const [symbol, set_symbol] = useState("");
    const [courseActive, setCourseActiveStatus] = useState(null);
    const [allCoursesActive, setAllCoursesActiveStatus] = useState(false);

    const lang = useLang();

    useEffect(() => {
      const dec_prices = JSON.parse(prices);

      if (auth.courses.length > 0 && dec_prices !== null && Object.keys(dec_prices).length > 0) {
        const isActive = auth.courses.indexOf(ID);
        const isAllCoursesActive = auth.courses.indexOf(Number(process.env.GATSBY_ALL_COURSES_ID));

        if (isActive === -1) {
          setCourseActiveStatus(false);
        } else {
          setCourseActiveStatus(true);
        }

        if (isActive === -1 && isAllCoursesActive >= 0 && dec_prices[ID].type === "one_time_payment" && promo_acceptable !== "no") {
          setAllCoursesActiveStatus(true);
        } else {
          setAllCoursesActiveStatus(false);
        }
      } else {
        setCourseActiveStatus(false);
      }

      if (dec_prices !== null && dec_prices[ID] !== undefined) {
        set_price(dec_prices[ID].price);
        set_price_old(dec_prices[ID].price_old);
        set_price_type(dec_prices[ID].type);
        set_details(dec_prices[ID].sub_details);
        set_symbol(dec_prices.currency.symbol);
      }

      return () => {
        return false;
      };
    }, [prices, ID, exists, auth.courses, promo_acceptable]);

    const isBestseller = () => {
      return !free && reviews.count > 20;
    };

    const isNew = () => {
      let at = new Date(created_at);
      let now = new Date();

      let Difference_In_Time = now.getTime() - at.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return Difference_In_Days < 45;
    };

    const isDescounted = () => {
      return price_old !== "" && price !== price_old;
    };

    const link = courseActive
      ? `${wpURL(lang)}/learner/my-purchased-courses/?section=course&obj=${ID}&lecture=1&content_type=video`
      : `/course/${permalink.category}/${permalink.slug}/`;

    return (
      <Link
        to={link}
        style={{ width: 255, height: 405, textDecoration: "none" }}
        className="text-brand-link-gray bg-white flex flex-col rounded-brand shadow-sm border border-gray-300 hover:shadow"
        title={title}
      >
        <div className="relative">
          {isBestseller() && (
            <CourseTag className="absolute right-0 bottom-0  mb-2">
              <Trans>Bestseller</Trans>
            </CourseTag>
          )}
          {!isBestseller() && isNew() && (
            <CourseTag className="absolute right-0 bottom-0  mb-2">
              <Trans>New</Trans>
            </CourseTag>
          )}
          <div className="" style={{ height: 160 }}>
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_380,h_240,c_fill,g_auto,q_auto:best/${image}`}
              lazyBgUrl={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_1,h_1,q_auto:low/${image}`}
              alt=""
              style={{
                height: 160,
                width: 253,
                objectFit: "cover",
                borderRadius: "10px 10px 0 0",
              }}
            />
          </div>
        </div>
        <div className="px-4 flex flex-wrap h-full">
          <div className="w-full">
            {/* { Aca va el titulo del curso} */}
            <div className="text-left font-semibold text-base leading-snug text-gray-700 mt-3 h-12 line-clamp-2">
              {/* <ParagraphShortening text={title} howMuch={43} /> */}
              {title}
            </div>
            {/* { Aca va la barra de detalles} */}
            <div className="flex justify-between text-sm mt-2 w-full">
              <span>
                {students > 10 ? (
                  <div className="text-center leading-tight">
                    <span className="icon-user mr-1" />
                    {students} <Trans>students</Trans>
                  </div>
                ) : (
                  <>
                    <span className="icon-book-open mr-1" />
                    {total_lectures} <Trans>lectures</Trans>
                  </>
                )}
              </span>
            </div>
            {/* { Aca va rating del curso} */}
            <div className="text-left flex justify-between my-2 h-5 text-sm whitespace-nowrap mt-4">
              <div className="relative">
                {reviews.count > 10 ? (
                  <>
                    <Rating reviews_avg={reviews_avg} className="text-sm" />
                    <span className="text-base ml-1 align-middle">{reviews.count}</span>
                  </>
                ) : (
                  <>
                    <span className="icon-file-certificate mr-1" />
                    <Trans>Certificate included</Trans>
                  </>
                )}
              </div>
            </div>
            {/* { Aca va datos del autor} */}
            <div className="flex items-center mt-3 text-left mb-1">
              <span className="text-sm">
                <Trans>By</Trans>{" "}
                <span className="font-medium">
                  <ParagraphShortening text={tutor.name} />
                </span>
              </span>
            </div>
          </div>

          {/* esta es la parte de abajo donde esta el precio  */}
          <div className="w-full self-end pb-3">
            {/* { Aca va call to action} */}
            {courseActive === false && allCoursesActive === false ? (
              <>
                <div className="text-left">
                  <span className="text-xs flex items-center space-x-1">
                    {!free && price !== "" && isDescounted() && (
                      <>
                        <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand">
                          {Math.floor(100 - (Number(price) * 100) / Number(price_old))}% OFF
                        </span>
                        <span className="line-through">
                          {price_old} {symbol}
                        </span>
                      </>
                    )}
                    {details !== "" && (
                      <>
                        {details.period_amount !== "" && (
                          <>
                            <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand uppercase">
                              <Trans>Sale</Trans>
                            </span>
                            <span className="line-through text-gray-600 mr-2">
                              {price} {symbol}
                              {price_type !== "one_time_payment" && (
                                <>
                                  {price_type === "monthly_subscription" && (
                                    <span>
                                      {" "}
                                      / <Trans>month</Trans>
                                    </span>
                                  )}
                                  {price_type === "weekly_subscription" && (
                                    <span>
                                      {" "}
                                      / <Trans>week</Trans>
                                    </span>
                                  )}
                                </>
                              )}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </span>
                </div>
                <div className="text-base flex items-center justify-between mt-2">
                  <button
                    className={`btn btn-primary flex-1 leading-tight whitespace-nowrap text-center ${price_type !== "one_time_payment" && "text-sm"}`}
                    style={{ height: 36, marginRight: 10 }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   navigate("/checkout", locale);
                    // }}
                    disabled={price === ""}
                  >
                    {price === "" ? (
                      <Loader />
                    ) : (
                      <>
                        {free ? (
                          <span className="uppercase">
                            <Trans>Free</Trans>
                          </span>
                        ) : (
                          <>
                            {/* <Trans>Buy now</Trans> */}
                            {details !== "" && details.period_amount === "-1" ? (
                              <>
                                {details.period_price === "free" ? (
                                  <span className="uppercase">
                                    <strong>
                                      <Trans>Free</Trans>
                                    </strong>
                                  </span>
                                ) : (
                                  <>
                                    {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {details !== "" && details.period_amount > 0 ? (
                                  <>
                                    {details.period_price === "free" ? (
                                      <span className="uppercase">
                                        {details.period_amount} <Period amount={details.period_amount} period={details.period} /> <Trans>Free</Trans>
                                      </span>
                                    ) : (
                                      <>
                                        {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {price} {symbol}
                                    {price_type !== "one_time_payment" && (
                                      <>
                                        {price_type === "monthly_subscription" && (
                                          <span>
                                            {" "}
                                            / <Trans>month</Trans>
                                          </span>
                                        )}
                                        {price_type === "weekly_subscription" && (
                                          <span>
                                            {" "}
                                            / <Trans>week</Trans>
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </button>
                  <CartButton {...{ price, type: price_type, exists, actionFunc, ID, image, title, link }} />
                </div>
              </>
            ) : (
              <>
                {allCoursesActive ? (
                  <Button className="leading-tight flex items-center justify-center space-x-2" style={{ height: 36 }}>
                    <span className="uppercase">
                      <Trans>Add course</Trans>
                    </span>
                    <span className="icon-plus text-xl ml-2" />
                  </Button>
                ) : (
                  <Button className="leading-tight flex items-center justify-center space-x-2" style={{ height: 36 }}>
                    {courseActive === null ? (
                      <Loader />
                    ) : (
                      <>
                        <span className="uppercase">
                          <Trans>View Course</Trans>
                        </span>
                        <span className="icon icon-play-circle-s" />
                      </>
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Link>
    );
  }
);

CourseCard.propTypes = {
  ID: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  reviews: PropTypes.object.isRequired,
  reviews_avg: PropTypes.number.isRequired,
  students: PropTypes.number.isRequired,
  free: PropTypes.bool.isRequired,
  permalink: PropTypes.object.isRequired,
  actionFunc: PropTypes.func.isRequired,
  // actionToMake: PropTypes.string.isRequired,
  total_lectures: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(CourseCard);
