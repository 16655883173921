import React from "react";
import LazyLoad from "vanilla-lazyload";
import classNames from "classnames";

const lazyloadConfig = {
  elements_selector: ".lazy",
};

// Only initialize it one time for the entire application
if (typeof window !== "undefined") {
  if (!document.lazyLoadInstance) {
    document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
  }
}

export class LazyImage extends React.Component {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    document.lazyLoadInstance.update();
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    document.lazyLoadInstance.update();
  }

  // Just render the image with data-src
  render() {
    const {
      alt,
      src,
      srcset,
      sizes,
      width,
      height,
      style,
      className,
      lazyBgUrl,
    } = this.props;
    const imgClass = classNames("lazy", className);

    const lazyBg =
      lazyBgUrl === undefined
        ? {}
        : {
            backgroundImage: `url("${lazyBgUrl}")`,
          };

    return (
      <img
        alt={alt}
        className={imgClass}
        data-src={src}
        data-srcset={srcset}
        data-sizes={sizes}
        width={width}
        height={height}
        style={{ ...style, ...lazyBg }}
      />
    );
  }
}

LazyImage.Props = {
  lazyBgUrl: undefined,
};

export default LazyImage;
